@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300");
@import url("https://fonts.google.com/specimen/DM Sans?query=lat");
@import url("https://fonts.googleapis.com/css2?family=DM Sans+Black&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "DM Sans";
  color: #444444;
  background: #f5f5f5;
  margin: 0;
  padding: 0;

  overflow-x: hidden;
}

h1 {
  @apply text-4xl font-extrabold;
}
.icon_button {
  @apply shadow-[0px_3px_30px_rgba(193,214,194,0.16)] border p-2.5 rounded-[50%] border-solid border-[#b0b6b0] hover:bg-[#f2fbf3] hover:opacity-50 hover:scale-[1.15] cursor-pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Sans";
}

/* 
//scroll warapper */
.scroll-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scroll-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*------------------------------------------------------
TEXT EFFECTS
-----------------------------------------------------------*/

.neumorphicText {
  color: #f2fdfd;
  font-size: 2.7rem;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -6px 6px 15px rgba(8, 8, 8, 0.5),
    6px -6px 15px rgba(188, 212, 243, 0.8);
}
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: rgb(43, 194, 43);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: rgb(43, 194, 43);
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}
/* Index Page Header
------------------------------*/
.index-page .header {
  --background-color: rgba(255, 255, 255, 0);
  --heading-color: #ffffff;
  --nav-color: #ffffff;
}

/* Index Page Header on Scroll
------------------------------*/
.index-page.scrolled .header {
  --background-color: rgba(40, 58, 90, 0.9);
}

/*--------------------------------------------------------------
# Get Startet Button
--------------------------------------------------------------*/
.get-started-btn {
  background: #fefefe;
  border: 1px solid rgb(43, 194, 43);
  border-radius: 8px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 600;
  display: flex;
}

.get-started-btn:hover {
  background: rgb(43, 194, 43);
  color: #fff;
}

@media (max-width: 992px) {
  .get-started-btn {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }
}

.numberText {
  /* width: 78px; */
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  text-transform: capitalize;
  color: #e0e2e0;
  opacity: 0.8;
}
/* .stepsContainer{
  padding:
  
} */
.stepsColumn {
  width: 400px;
}
.numberDescription {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-transform: capitalize;
  color: #363636;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 0 0 80px 0;
}
.centered-section {
  padding: 0 100px 80px 100px;
}

.section-bg {
  background-color: #f5f5f5;
}
.section-bg-green {
  background: #f2fbf3;

  border: 1px solid #e4efe4;
  /* border-radius: 15px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 30px;
}

.section-image {
  width: 300px;
  height: 100%;
}
.section-title h2 {
  color: #191919;
  font-family: "DM Sans";
}
/* Frequently Asked Questions */

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: rgb(43, 194, 43);
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .centered-section {
    padding: 0 0 80px 0;
  }

  .navbar ul {
    display: none;
  }
}

.arrowIcon {
  width: 20px;
}

/*-------------------------------------------------------------
Green Outline Button
------------------------------------------------------------------*/
.green-outline-button {
  background: #fefefe;
  border: 1px solid rgb(43, 194, 43);
  border-radius: 8px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 600;
  display: flex;
}

.green-outline-button:hover {
  background: rgb(43, 194, 43);
  color: #fff;
}

@media (max-width: 992px) {
  .green-outline-button {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }
}
/*-------------------------------------------------------------
Green Button
--------------------------------------------------------------------*/

.green-button {
  cursor: pointer;
  background: linear-gradient(180deg, #468955 0%, #1c5f2b 100%);
  box-shadow: 0px 3px 32px rgba(30, 97, 45, 0.2);
  border-radius: 8px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  color: #fefefe;
}
.green-button:hover {
  background: linear-gradient(180deg, #1c5f2b 100%, #468955 0%);
  color: #fff !important;
}

.blue-button {
  cursor: pointer;
  background: linear-gradient(180deg, #4a90e2 0%, #1c3f6b 100%);
  box-shadow: 0px 3px 32px rgba(30, 97, 45, 0.2);
  border-radius: 8px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  color: #fefefe;
}
.blue-button:hover {
  background: linear-gradient(180deg, #1c3f6b 100%, #4a90e2 0%);
  color: #fff !important;
}
/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0 20px 0;
  background: #f7f7f7;
  border-bottom: 1px solid #ededed;
  margin-bottom: 40px;
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 700;
  color: #545454;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 5px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6e6e6e;
  content: "/";
}

/*--------------------------------------------------------------
# Featured
--------------------------------------------------------------*/
.featured {
  position: relative;
  z-index: 2;
}

.featured .icon-box {
  padding: 40px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  height: 100%;
}

.featured .icon-box i {
  color: rgb(43, 194, 43);
  font-size: 42px;
  margin-bottom: 15px;
  display: block;
  line-height: 0;
}

.featured .icon-box h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
}

.featured .icon-box h3 a {
  color: #545454;
  transition: 0.3s;
}

.featured .icon-box p {
  color: #545454;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.featured .icon-box:hover {
  background: rgb(43, 194, 43);
}

.featured .icon-box:hover i,
.featured .icon-box:hover h3 a,
.featured .icon-box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
  color: #545454;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: rgb(43, 194, 43);
}

.about .content p:last-child {
  margin-bottom: 0;
}
/* Rectangle 4451 */
/* Ellipse 7 */

/*--------------------------------------------------------------
# Our Clients
--------------------------------------------------------------*/
.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid rgb(43, 194, 43);
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: rgb(43, 194, 43);
}

.clients .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
  filter: grayscale(100);
}

.clients .swiper-slide img:hover {
  filter: none;
  opacity: 1;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts .count-box {
  box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
}

.counts .count-box i {
  display: block;
  font-size: 30px;
  color: rgb(43, 194, 43);
  float: left;
  line-height: 0;
}

.counts .count-box span {
  font-size: 42px;
  line-height: 24px;
  display: block;
  font-weight: 700;
  color: #545454;
  margin-left: 50px;
}

.counts .count-box p {
  padding: 30px 0 0 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

.counts .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #7a7a7a;
  font-size: 15px;
  font-family: "DM Sans";
  transition: ease-in-out 0.3s;
}

.counts .count-box a:hover {
  color: #a1a1a1;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 40px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: white;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}

/* /*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
/* .skills .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #545454;
  font-family:"DM Sans";
  margin-bottom: 20px;
}

.skills .content ul {
  list-style: none;
  padding: 0;
}

.skills .content ul li {
  padding-bottom: 10px;
}

.skills .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: rgb(43, 194, 43);
}

.skills .content p:last-child {
  margin-bottom: 0;
}

.skills .progress {
  height: 62px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family:"DM Sans";
  color: #545454;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #e0e0e0;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: rgb(43, 194, 43);
}  */

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  background: white;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 12px 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 4px 8px 4px;
  transition: all ease-in-out 0.3s;
  border-radius: 50px;
  background: #f2f2f2;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: rgb(43, 194, 43);
  color: #fff;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(84, 84, 84, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(84, 84, 84, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: rgb(43, 194, 43);
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid rgb(43, 194, 43);
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: rgb(43, 194, 43);
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(84, 84, 84, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# skill
--------------------------------------------------------------*/
.skill .member {
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  background: #fff;
}

.skill .member img {
  max-width: 60%;
  border-radius: 50%;
  margin: 0 0 30px 0;
}

.skill .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

.skill .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

.skill .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #aaaaaa;
}

.skill .member .social {
  margin-top: 15px;
}

.skill .member .social a {
  color: #919191;
  transition: 0.3s;
}

.skill .member .social a:hover {
  color: rgb(43, 194, 43);
}

.skill .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 32px;
  color: rgb(43, 194, 43);
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #fef5f4;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: rgb(43, 194, 43);
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: rgb(43, 194, 43);
  border: 0;
  border-radius: 50px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #e6573f;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 440px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #545454;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: rgb(43, 194, 43);
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #bababa;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li + li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}

.blog .entry .entry-meta a {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: rgb(43, 194, 43);
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #ec7f6d;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #545454;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #a1a1a1;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #616161;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: rgb(43, 194, 43);
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}

.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #545454;
}

.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
  color: rgba(84, 84, 84, 0.5);
  margin-right: 5px;
}

.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
  width: 60px;
}

.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: rgb(43, 194, 43);
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #545454;
}

.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #6e6e6e;
  margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #f5bab0;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #f5bab0;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #545454;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #616161;
}

.blog .blog-pagination {
  color: #878787;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #545454;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: rgb(43, 194, 43);
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #545454;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: rgb(43, 194, 43);
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #eb7b68;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #545454;
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: rgb(43, 194, 43);
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #545454;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: rgb(43, 194, 43);
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #949494;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid white;
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid rgb(43, 194, 43);
  background: rgb(43, 194, 43);
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #ededed;
  font-size: 14px;
}
