/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #0e120e;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-top {
  background: #0e120e;
  border-top: 1px solid #474747;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #545454;
  color: #fff;
  line-height: 1;
  padding: 10px 10px;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: rgb(43, 194, 43);
  color: #fff;
  text-decoration: none;
}

#footer .social-links a img {
  width: 100%;
}
#footer .footer-top h4 {
  font-size: 22px;
  color: #fff;
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #9d9d9d;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  font-size: 16px;
}

#footer .footer-top .footer-links ul a:hover {
  color: rgb(43, 194, 43);
}

#footer .copyright {
  text-align: left;
  padding-top: 10px;
  color: #9d9d9d;
}

#footer .payment-section {
  background: #141714;
  border-radius: 20px;
  margin-bottom: 30px;
}

#footer .payment-section a {
  font-size: 18px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  text-align: left;
  transition: 0.3s;
  cursor: pointer;
}
#footer .payment-section a:hover {
  background: #141714;
  opacity: 0.6;
}
#footer .payment-section a img {
  width: 50px !important;
}
#footer .app-buttons a img {
  width: 10rem !important;
  cursor: pointer;
}
#footer .app-buttons a img:hover {
  border: 2px solid green;
  box-shadow: 0 4px 6px rgba(112, 110, 110, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 10px;
}
@media (max-width: 991px) {
  #footer .payment-section a img {
    width: 100%;
    padding: 3px;
  }
  #footer .app-buttons a img {
    width: 50%;
  }
}
