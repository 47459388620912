/*--------------------------------------------------------------
  Experts
--------------------------------------------------------------*/
.experts .heading {
  text-align: left;
  padding: 0;
}
.experts .heading h2 {
  font-size: 28px;
  font-weight: bolder;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #000;
  font-family: "DM Sans";
  /* font-family: "Archivo"; */
}
.experts.section-bg {
  background-color: #ffff;
  padding: 0 0.5rem 0  0.5rem
}
.experts .expert-item {
  background: #ffffff;
  border: 1px solid #e4efe4;
  box-shadow: 2px 3px 28px rgba(212, 223, 212, 0.12);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 5px;
  transition: 0.5s;
  padding: 10px;
  min-height: 100%;
  cursor: pointer;
}

@media (max-width: 468px) {
  .experts .expert-item {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }
}

.experts .expert-item .pic {
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 20px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  flex-shrink: 0;
}

.experts .expert-item .pic img {
  transition: ease-in-out 0.3s;
}

.experts .expert-item:hover {
  /* transform: translateX(-10px); */
  transform: translateY(-10px);
}

.experts .expert-item .expert-info .expert-profile {
  display: inline-block;
}
.experts .expert-item .expert-info .expert-profile .expert-levels {
  display: inline-block;
}
.experts .expert-item .expert-info .expert-profile .profile-picture {
  margin-bottom: 10px;
  text-align: left;
  justify-content: flex-start;
  box-shadow: 0px 3px 30px rgba(193, 214, 194, 0.16);
  width: 48px;
  height: 48px;
  background-color: #f2fbf3;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f2fbf3;
  filter: drop-shadow(2px 3px 24px rgba(67, 159, 70, 0.06));
}

.experts .expert-item .expert-info .expert-profile .profile-picture img {
  width: 90%;
  height: auto;
  border-radius: 50%;
}

@media (max-width: 468px) {
  .experts .expert-item .expert-info {
    padding: 30px 0 0 0;
    text-align: center;
  }
}

.experts .expert-item h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
}
.experts .expert-item .expert-qualifications {
  border-bottom: 1px solid #9b9a9a;
  padding-bottom: 30px;
}
.experts .expert-item .expert-qualification {
  background-color: #fefefe;
  border: 1px solid #d9d9d9;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 8px 5px;
}
.experts .expert-item span {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
}

.experts .expert-item span::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 50px;
  background: color-mix(in srgb, rgb(43, 194, 43), transparent 85%);
}

@media (max-width: 468px) {
  .experts .expert-item span::after {
    left: calc(50% - 25px);
  }
}

.experts .expert-item p {
  margin: 10px 0 0 0;
  font-size: 14px;
}
.experts .expertsButton {
  background: #fefefe;
  border: 1px solid rgb(43, 194, 43);
  border-radius: 8px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 600;
  display: flex;
}
.experts .expert-profile {
  display: inline-block;
}
.experts .expertsButton:hover {
  background: rgb(43, 194, 43);
  color: #fff;
}

@media (max-width: 992px) {
  .experts .expertsButton {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }
}

.expertise ul {
  padding: 20px 0;
  list-style: none;
  text-align: left;
  line-height: 20px;
}

.expertise ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.expertise ul .image {
  color: #059652;
  font-size: 24px;
  padding-right: 3px;
}

.pricing ul li .na {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.pricing ul li .na i {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.expertise ul .na span {
  text-decoration: line-through;
}

.experts .expert-item .pic .favorite-icon,
.experts .expert-item .pic .ratings-icon {
  position: absolute;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Light background to make icons visible */
  padding: 5px;
}

.experts .expert-item .pic .favorite-icon {
  opacity: 0.8;
  top: 10px;
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Perfect circle */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  right: 10px;
  padding: 3px;
}

.experts .expert-item .pic .ratings-icon {
  bottom: 10px;
  left: 10px;
  padding: 8px;
  border-radius: 20px;
  background: #fefefe;
  display: inline-block;
}
.experts .experts-item .pic .ratings-icon img {
  background: #000;
}
