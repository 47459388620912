#social-proof {
  border-radius: 20px;
}
#social-proof .proof-card {
  cursor: pointer;
  border: 1px solid #e4efe4;
  /* box-shadow: 2px 3px 28px rgba(212, 223, 212, 0.12); */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
  border-radius: 40px;
  position: relative;
  border-radius: 5px;
  transition: 0.5s;
  min-height: 100%;
  cursor: pointer;
  background: #f5f5f5;
}

#social-proof .proof-card:hover {
  /* transform: translateX(-10px); */
  box-shadow: 3px 4px 30px rgba(57, 165, 70, 0.5);
  border: 1px solid rgba(57, 165, 70, 0.5);
}

#social-proof span {
  color: rgb(43, 194, 43);
  border-bottom: 1px solid #545454;
}
#social-proof .star {
  background: #000;
  padding: 5px;
}
#social-proof .logo {
  width: 50%;
}

#social-proof .next-button {
  width: 25px;
}

.arrow-container {
  position: relative;
  width: 64px; /* Adjust size as needed */
  height: 64px; /* Adjust size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.arrow-circle {
  position: absolute;
  width: 38px; /* Adjust size as needed */
  height: 38px; /* Adjust size as needed */
  background-color: transparent; /* Tailwind's gray-200 */
  border-radius: 50%;
  border: 1px solid #439f46;
}

.arrow-button {
  position: absolute;
  /* z-index: 10; */
  border: none;
  background: none;
  cursor: pointer;
  width: 40px !important;
}

.arrow-button img {
  width: 32px; /* Adjust size as needed */
  height: 32px; /* Adjust size as needed */
}

.previous-arrow img {
  width: 40px !important;
}
.active-previous-arrow img {
  transform: translateX(-18px);
  width: 60px !important;
}
.next-arrow img {
  width: 40px !important;
}
.active-next-arrow img {
  width: 60px !important;
  transform: translateX(18px); /* Adjust position as needed */
}
