/*--------------------------------------------------------------
# Faqs Section
--------------------------------------------------------------*/
.faq .faq-container {
  margin-top: 15px;
  width: 100%;
}

.faq .faq-container .faq-item {
  background-color: #ffffff;
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  border: 1px solid #e9ece9;
  border-radius: 10px;
}

.faq .faq-container .faq-item:last-child {
  margin-bottom: 0;
}

.faq .faq-container .faq-item h2 {
  font-size: 18px;
  line-height: 24px;
  margin: 0 30px 0 0px;
  transition: 0.3s;
}

.faq .faq-container .faq-item h2 span {
  padding-right: 5px;
}

.faq .faq-container .faq-item h2:hover {
  color: #429546;
}

.faq .faq-container .faq-item .faq-content {
  /* padding: 1rem; */
  padding-top: 10px;
  transition: 0.3s ease-in-out;
}

.faq .faq-container .faq-item .faq-content p {
  margin-bottom: 0;
}

.faq .faq-container .faq-item .faq-icon {
  position: absolute;
  top: 22px;
  right: 20px;
  font-size: 10px;
  line-height: 0;
  transition: 0.3s;
}
.faq .faq-container .faq-item .faq-icon image {
  width: 40px;
  height: 40px;
}
.faq .faq-container .faq-item .faq-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 0;
  transition: 0.3s;
  cursor: pointer;
}

.faq .faq-container .faq-active h2 {
  color: #429545;
}

.faq .faq-container .faq-active .faq-content {
  grid-template-rows: 1fr;
  visibility: visible;
  opacity: 1;
  padding-top: 10px;
}

.faq .faq-container .faq-active .faq-toggle {
  transform: rotate(90deg);
  color: var(--accent-color);
}
